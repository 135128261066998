@import  "../color.module.scss";

.e_switch {
    width: 3rem; /* 48px converted to rem */
    height: 1.5rem; /* 24px converted to rem */
    .e_head {
        width: 1.25rem; /* 20px converted to rem */
        height: 1.25rem; /* 20px converted to rem */
        top: 1.8px;
        transition: left 0.3s ease;
    }
}
