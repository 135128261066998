@import "../color.module.scss";

.e_navigation_items {
    &:hover {
        background-color: #bccbd8;
        span {
            color: $dark_charcoal;
        }
    }
}

.e_active_navigation_items {
    &:hover {
        background-color: $navy_blue;
        span {
            color: $white;
        }
    }
}

.e_navigation_items svg {
    stroke: #23262f;
}
.e_active_navigation_items svg {
    stroke: $white !important;
}
// .e_navigation_items .e_inactive_icon {
//     display: block;
// }
// .e_active_navigation_items .e_inactive_icon {
//     display: block;
// }

// .e_navigation_items .e_active_icon {
//     display: none;
// }
// .e_active_navigation_items .e_active_icon {
//     display: none;
// }

// .e_navigation_items:hover .e_inactive_icon {
//     display: none;
// }

// .e_navigation_items:hover .e_active_icon {
//     display: block;
// }
.e_menu_item_wrapper {
    height: calc(97% - 104px);
    overflow-y: scroll;
    overflow-x: hidden;

    // scroll styles
}

.e_menu_item_wrapper::-webkit-scrollbar {
    width: 8px !important;
    height: 0px !important;
    display: block !important;
}
.e_menu_item_wrapper::-webkit-scrollbar-thumb {
    background: $scrollbar_color;
}
