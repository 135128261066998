@charset "utf-8";
/* ==============================
   Small desktop
   ============================== */

@import "./color.module.scss";

@media only screen and (max-width: 1400px) {
    .e-login .e-wrapper {
        width: 35vw;
    }
    .e-login {
        height: 100% !important;
    }
}

@media only screen and (max-width: 1200px) {
    .e-login .e-wrapper {
        width: 40vw;
    }
}

@media only screen and (max-width: 992px) {
    .e-login .e-wrapper {
        width: 70vw;
    }
}

@media only screen and (max-width: 768px) {
    .e-login .e-wrapper {
        width: 80vw;
    }
}

@media only screen and (max-width: 576px) {
    .e-login .e-wrapper {
        width: 90vw;
    }
}
