@import  "../color.module.scss";

.e_input_wrapper {
    .e_input {
        border: 1px solid $pale_gray;
        /* 1px converted to rem */
        border-radius: 0.5rem;
        padding: 0.5rem;
        color: $dark_charcoal;

        &:focus {
            border-style: solid;
            outline: none;
            border-width: 1px;
            border-color: $pale_gray;
        }

        /* 4px converted to rem */
    }

    .e_textarea {
        border: 1px solid $pale_gray;
        /* 1px converted to rem */
        border-radius: 0.5rem;
        padding: 0.5rem;
        /* 4px converted to rem */
    }

    .e_input,
    textarea {

        &:focus~label,
        &:valid~label,
        &[readonly][value]:not([value=""])~label {
            top: 0 !important;
            // padding: 0.375rem !important;
            left: 1.5rem;
            color: $dark_charcoal;
        }
    }
}

.e_error_input {

    .e_input,
    textarea {
        border: 0.0625rem solid red !important;
        /* 1px converted to rem */
    }
}

.e_error_input label {
    color: red !important;
}