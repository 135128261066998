@import "../color.module.scss";

.e_search_input {
    .e_submit {
        background: $navy_blue;
        border-radius: 0 0.5rem 0.5rem 0;
        span {
            height: 100%;
            padding: 0px;
            display: flex;
            align-items: center;
        }
    }

    input {
        caret-color: black;
        color: #4d4d4d;
        font-size: 16px;
        font-family: "Montserrat-Medium";
        border-radius: 16px;
        border-width: 1px;
        border-style: solid;
        border-radius: 0.5rem;
        background: #fff;
        width: 100%;
        padding: 0.75rem 3.8rem 12px 2.5rem;
        &:focus {
            border-style: solid;
            outline: none;
            border-width: 1px;
            // border-color: $stroke_gray;
        }
        &::placeholder {
            font-size: 0.875rem;
            line-height: 1.5rem !important;
            font-family: "Montserrat-Regular";
            color: $dark_charcoal;
            text-transform: capitalize;
        }
    }
}

.e_search_select {
    .e_submit {
        background: $navy_blue;
        border-radius: 0 0.5rem 0.5rem 0;
        span {
            height: 100%;
            padding: 0px;
            display: flex;
            align-items: center;
        }
    }

    input {
        caret-color: black;
        color: #4d4d4d;
        font-size: 16px;
        font-family: "Montserrat-Medium";
        border-width: 1px;
        border-style: solid;
        border-radius: 0.5rem;
        background: #fff;
        width: 100%;
        padding: 14px 2.2rem 14px 2.2rem;
        &::placeholder {
            font-size: 0.875rem;
            line-height: 1.5rem !important;
            font-family: "Montserrat-Regular";
            color: $dark_charcoal;
            text-transform: capitalize;
        }
        &:focus {
            border-style: solid;
            outline: none;
            border-width: 1px;
            border-color: $navy_blue;
        }
    }
    .e_fund:hover {
        background: $lavender_gray !important;
        cursor: pointer;
    }
}

.e_error_input {
    input {
        border: 0.0625rem solid red !important;
        /* 1px converted to rem */
    }

    input::placeholder {
        color: red;
        font-size: 1rem;
        font-family: "Montserrat-Regular";
    }
}
