@import "../color.module.scss";

.e_resource_publish_later {
    .e_dialog {
        max-width: 45rem;

        .e_content {
            border-radius: 1.75rem;
            background: $white;

            .e_date_input {
                border: solid 1px $light_gray !important;
                padding: 11px 16px !important;
                // line-height: 20px;
            }
        }
    }
}