@import "../color.module.scss";
.e_text_button,
.e_delete_button {
    display: flex;
    align-items: center;
    position: relative;
    width: max-content;
}

.e_text_button {
    color: $navy_blue;
    &:before {
        content: "";
        position: absolute;
        width: 0;
        left: 0px;
        height: 1px;
        background-color: $navy_blue;
        bottom: 0;
        transition: all 0.35s;
    }
    &:hover {
        &:before {
            width: 100%;
        }
        cursor: pointer;
        opacity: 1;
    }
}

.e_delete_button {
    color: $deep_carmine_pink;
    &:before {
        content: "";
        position: absolute;
        width: 0;
        left: 0px;
        height: 1px;
        background-color: $deep_carmine_pink;
        bottom: 0;
        transition: all 0.35s;
    }
    &:hover {
        &:before {
            width: 100%;
        }
        cursor: pointer;
        opacity: 1;
    }
}

.e_text_button .e_arrow,
.e_delete_button .e_arrow {
    transition: 0.3s all ease-in-out;
    transform: translateX(0px);
}

.e_text_button:hover .e_arrow,
.e_delete_button:hover .e_arrow {
    transition: 0.3s all ease-in-out;
    transform: translateX(5px);
}
