@import "../color.module.scss";

.e_add_fund {
    .e_dialog {
        max-width: 46rem;

        .e_content {
            border-radius: 1.75rem;
            background: $white;
            border: none;

            .e_search_input {
                background-color: $stroke_gray !important;
            }

            .e_wrapper {
                height: 350px;
                overflow-y: scroll;
                overflow-x: hidden;
                padding-right: 10px;

                &::-webkit-scrollbar {
                    width: 8px !important;
                    height: 0px;
                    display: block !important;
                }

                &::-webkit-scrollbar-thumb {
                    background-color: $scrollbar_color !important;
                }
            }
        }
    }
}
