@import "../color.module.scss";

.e_scroll {
    &::-webkit-scrollbar {
        display: none;
    }
}

.e_tab {
    transition: ease 0.3s;
    padding: 14px 0;
    position: relative;
    &:before {
        content: "";
        position: absolute;
        width: 0;
        left: 0px;
        height: 3px;
        background-color: $navy_blue;
        bottom: 0;
        transition: all 0.35s;
        border-radius: 100px 100px 0px 0px;
    }
    &:hover {
        &:before {
            width: 100%;
        }
        cursor: pointer;
        opacity: 1;
    }
}

.e_active_tab {
    transition: ease 0.3s;
    padding: 14px 0;
    position: relative;
    &:before {
        content: "";
        position: absolute;
        left: 0px;
        width: 100%;
        height: 3px;
        background-color: $navy_blue;
        bottom: 0;
        cursor: pointer;
        opacity: 1;
        transition: all 0.35s;
        border-radius: 100px 100px 0px 0px;
    }
}
