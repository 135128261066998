@import "../color.module.scss";

.e_timer {
    width: 100%;

    input {
        padding: 14px 16px;
        border: 1px solid #bdbdbd;
        border-radius: 8px;
        font-size: 16px;
        font-family: "Montserrat-medium";
        color: $charleston_green;
        line-height: 18px;
        height: unset;

        &:focus-within {
            outline: none;
        }
        &::placeholder {
            font-family: "Montserrat-Regular" !important;
            color: $dark_charcoal;
        }
    }

    .e_icon {
        right: 16px;
        top: 0;
        bottom: 0;
        margin: auto;
    }
}

.e_error {
    bottom: -24px;
    left: 0;
}
