/*
 *   File : font.scss
 *   Author URI : https://evoqueinnovativelab.com
 *   Description : Index page for font
 *   Integrations :
 *   Version : v1.0
 *   Created : 
 */
/* Import Fonts */

/*----- Montserrat font family ------*/

@font-face {
    font-family: "Montserrat-Bold";
    src: url(../Assets/Fonts/Montserrat/Montserrat-Bold.ttf);
    // font-weight: 700;
}

@font-face {
    font-family: "Montserrat-SemiBold";
    src: url(../Assets/Fonts/Montserrat/Montserrat-SemiBold.ttf);
    // font-weight: 600;
}

@font-face {
    font-family: "Montserrat-Medium";
    src: url(../Assets/Fonts/Montserrat/Montserrat-Medium.ttf);
    // font-weight: 500;
}

@font-face {
    font-family: "Montserrat-Regular";
    src: url(../Assets/Fonts/Montserrat/Montserrat-Regular.ttf);
    // font-weight: 400;
}

@font-face {
    font-family: "Montserrat-Light";
    src: url(../Assets/Fonts/Montserrat/Montserrat-Light.ttf);
    // font-weight: 300;
}
