@import "../color.module.scss";

.e_modal {
    .e_dialog {
        max-width: 30rem;

        .e_content {
            border-radius: 1.75rem;
            background: $white;
            border: none;

            .e_modal_scroll {
                height: 478px;
                overflow-y: scroll;
                overflow-x: hidden;

                &::-webkit-scrollbar {
                    width: 8px;
                    height: 0rem;
                }

                &::-webkit-scrollbar-thumb {
                    background-color: $scrollbar_color;
                    border-radius: 0.5rem;
                }
            }
        }
    }
}