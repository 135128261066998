@import "../color.module.scss";

.e_input_wrapper {
    &[data-disabled="true"] {
        pointer-events: none;
        cursor: default;
    }

    .e_input,
    .e_textarea {
        border: 0.063rem solid $light_gray;
        border-radius: 0.5rem;
        padding: 14px 16px;
        outline: none;

        &::placeholder {
            font-size: 0.875rem;
            line-height: 1.5rem !important;
            font-family: "Montserrat-Regular";
            color: $dark_charcoal;
            text-transform: capitalize;
        }
    }

    .e_input:disabled {
        background-color: transparent;
    }

    .e_textarea {
        border: 0.063rem solid $light_gray;
        /* 1px converted to rem */
        border-radius: 0.5rem;
        padding: 0.75rem 0.875rem;
    }

    .e_input,
    .e_textarea {
        &:focus // &:valid
        {
            border-color: $navy_blue;
        }
    }

    // .e_input,
    // .e_textarea {
    //     &:not(:placeholder-shown) {
    //         border-color: $navy_blue !important;
    //     }
    // }

    textarea {
        resize: none;
    }
}
