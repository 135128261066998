@import "../color.module.scss";

.e_editor_toolbar {
    background: #f9f9f9;
    border: none;
    border-radius: 2px;

    div {
        // background: transparent;
        border: none;
    }
}

.e_editor {
    font-family: "Montserrat-Medium";
    height: 266px;
    border-top: 1px solid $light_gray;
    margin-top: 16px;

    &::-webkit-scrollbar {
        width: 4px;
        height: 0.5rem;
    }

    &::-webkit-scrollbar-thumb {
        background-color: $scrollbar_color;
        border-radius: 0.5rem;
    }
}

.e_error {
    bottom: -24px;
    left: 0;
}

.link_dropDown {
    height: auto;
}
