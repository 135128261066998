@import  "../color.module.scss";

.e_modal {
    .e_dialog {
        max-width: 40rem;

        .e_content {
            border-radius: 1.75rem;
            background: $white;
            border: none;
        }
    }
}