@import  "../color.module.scss";

.e_modal {
    .e_dialog {
        max-width: 31.875rem;

        .e_content {
            border-radius: 2rem;
            background: $white;
            border: none;
        }
    }
}